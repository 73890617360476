import React, { useEffect, useState } from 'react';
import PageWrapper from '@bit/together-price.core.components.page-wrapper';
import { useHistory } from 'react-router-dom';
import { fade, Grid, makeStyles, Typography } from '@material-ui/core';
import { Trans, useTranslation } from 'react-i18next';
import Process from '@bit/together-price.core.components.process';
import {
  useApp,
  useNavigateToUrl,
} from '@bit/together-price.core.components.app-context';
import Button from '@bit/together-price.core.components.button';
import { Money } from '@bit/together-price.core.monetary';
import moment from 'moment';
import useStripeTranslation from '@bit/together-price.core.hooks.use-stripe-translation';
import { useSWRConfig } from 'swr';
import SuccessIcon from '@/icons/SuccessIcon';
import { useOrderStatus, useStoreCatalog } from '@/api/store';
import useCatalogName from '@/hooks/useCatalogName';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(10, 4),
  },
  header: { display: 'flex', flexDirection: 'row', alignItems: 'center' },
  icon: {
    height: 'auto',
    width: '100%',
    maxWidth: 250,
    margin: '60px auto',
  },
  planCard: {
    backgroundColor: fade(theme.palette.primary.light, 0.2),
    borderColor: theme.palette.primary.light,
    borderStyle: 'solid',
    borderWidth: 3,
    borderRadius: theme.spacing(2.5),
    padding: theme.spacing(2.5, 4),
    margin: theme.spacing(2, 0),
  },
  planCardTitle: { marginBottom: theme.spacing(1.5) },
  button: {
    marginTop: 'auto',
  },
  headerButton: { textTransform: 'none', padding: theme.spacing(0, 4) },
  appbar: { flex: 1 },
  process: {
    flex: 1,
    backgroundColor: 'transparent',
  },
}));

const StorePaymentStatus = ({ match }) => {
  const navigateToUrl = useNavigateToUrl();
  const { setError } = useApp();
  const [isReady, setIsReady] = useState(false);
  const [show, setShow] = useState(false);
  const { mutate } = useSWRConfig();
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const { tStripe } = useStripeTranslation();
  const { uuid } = match.params;
  const { data: orderStatus } = useOrderStatus({
    uuid,
    isReady,
  });
  const catalogName = useCatalogName();
  const { mutate: mutateCatalog } = useStoreCatalog(catalogName);
  useEffect(() => {
    if (orderStatus) {
      mutateCatalog();
    }
  }, [orderStatus]);

  if (!orderStatus) return <div />;
  if (orderStatus.status === 'INVALID') {
    setError(<Trans i18nKey='error' />);
    history.goBack();
    return <div />;
  }
  return (
    <PageWrapper
      activeTab='home'
      navigateToUrl={navigateToUrl}
      classes={{ mobileInner: classes.root, header: classes.header }}
      headerChildren={
        <Button
          onClick={() => navigateToUrl('/store/purchases')}
          variant='text'
          color='primary'
          className={classes.headerButton}
        >
          <Typography variant='subtitle2' color='primary'>
            <Trans i18nKey='purchases' />
          </Typography>
        </Button>
      }
      FullTopAppBarProps={{
        mobileProps: {
          classes: { appbar: classes.appbar },
          title: <Trans i18nKey='store' />,
          titleLine: true,
          onBack: history.goBack,
        },
      }}
    >
      {!show ? (
        <Process
          classes={{ root: classes.process }}
          variant={
            orderStatus.status === 'IN_PROCESS'
              ? 'processing'
              : (orderStatus.status.toLowerCase() as any)
          }
          callback={() => {
            setIsReady(true);
            if (orderStatus.status === 'SUCCESS') setShow(true);
          }}
          title={{
            processing: t('PaymentProcess.processing'),
            failed: t('PaymentProcess.failed'),
            success: t('PaymentProcess.done'),
          }}
          description={{
            processing: '',
            failed: '',
            success: '',
          }}
          {...(orderStatus.status === 'FAILED' && {
            body: {
              title: t('error'),
              description:
                orderStatus.errorsCode.length > 0
                  ? orderStatus.errorsCode.map((err) => {
                      const lowered = err.toLowerCase();
                      const stripeError = tStripe(err.toLowerCase());
                      if (stripeError !== lowered) return stripeError;
                    })
                  : [orderStatus.errorMessage].filter(Boolean),
            },
          })}
        />
      ) : (
        <>
          <Typography variant='h1' color='primary' align='center'>
            <Trans i18nKey='PaymentProcess.confirmed' />
          </Typography>
          <SuccessIcon className={classes.icon} />
          <Grid className={classes.planCard} container direction='column'>
            <Grid
              className={classes.planCardTitle}
              container
              direction='row'
              justifyContent='space-between'
            >
              <Typography color='primary'>
                <Trans
                  i18nKey={`Catalog.${orderStatus.name}.title`}
                  components={{
                    child: <div />,
                  }}
                />
              </Typography>
              <Typography variant='h2' color='primary'>
                {Money.toString(orderStatus.price)}
              </Typography>
            </Grid>
            <Typography variant='caption' color='textSecondary' align='right'>
              <Trans
                i18nKey='Payment.expiryDate'
                values={{
                  date: moment(orderStatus.expiredAt).format('DD/MM/yyyy'),
                }}
              />
            </Typography>
          </Grid>
          <Button
            className={classes.button}
            onClick={async () => {
              const isNOFEE = orderStatus.name === 'NO_FEE';
              await mutate('/store/getUserFeatures');
              navigateToUrl(`/${isNOFEE ? '?revalidate=true' : ''}`);
            }}
            variant='contained'
            color='primary'
            fullWidth
          >
            <Trans i18nKey='PaymentProcess.startSharing' />
          </Button>
        </>
      )}
    </PageWrapper>
  );
};

export default StorePaymentStatus;
