const getColumnName = (key: string) => {
  switch (key) {
    case 'NO_FEE':
      return 'NoMoreCommissions';
    case 'AUTO_PAYOUT_FEATURE':
      return 'AutomaticBankTransfer';
    case 'DEDICATED_SUPPORT':
      return 'CustomSupport';
    default:
      return key;
  }
};

export default getColumnName;
