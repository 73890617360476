import React from 'react';
import PageWrapper from '@dev-togetherprice/components.page-wrapper';
import { makeStyles, Typography } from '@material-ui/core';
import { Trans } from 'react-i18next';
import { useNavigateToUrl } from '@dev-togetherprice/components.app-context';
import { useHistory } from 'react-router-dom';
import Button from '@dev-togetherprice/components.button';
import StoreItem from '@/components/StoreItem';
import { useStoreCatalog } from '@/api/store';
import ICONS from '@/assets/icons';
import useCatalogName from '@/hooks/useCatalogName';

const useStyles = makeStyles((theme) => ({
  root: { padding: theme.spacing(4) },
  header: { display: 'flex', flexDirection: 'row', alignItems: 'center' },
  mobileHeader: { flexShrink: 1 },
  button: { padding: theme.spacing(0, 4) },
  appbar: { flex: 1 },
}));

const Store = () => {
  const navigateToUrl = useNavigateToUrl();
  const history = useHistory();
  const classes = useStyles();
  const catalogName = useCatalogName();
  const { data: catalog } = useStoreCatalog(catalogName);
  if (!catalogName) {
    history.goBack();
    return <div />;
  }

  return (
    <PageWrapper
      activeTab='home'
      classes={{ mobileInner: classes.root, header: classes.header }}
      headerChildren={
        <Button
          onClick={() => navigateToUrl('/store/purchases')}
          variant='text'
          color='primary'
          className={classes.button}
        >
          <Typography variant='subtitle2' color='primary'>
            <Trans i18nKey='purchases' />
          </Typography>
        </Button>
      }
      FullTopAppBarProps={{
        classes: { root: classes.mobileHeader },
        mobileProps: {
          classes: { appbar: classes.appbar },
          title: <Trans i18nKey='store' />,
          titleLine: true,
          onBack: history.goBack,
        },
      }}
      navigateToUrl={navigateToUrl}
    >
      {catalog?.storeItems.map(({ canBuy, tag, storePlans, expiredAt }) => (
        <StoreItem
          key={tag}
          link={tag}
          title={
            <Trans
              i18nKey={`Catalog.${tag}.title`}
              components={{ child: <div /> }}
            />
          }
          description={<Trans i18nKey={`Catalog.${tag}.description`} />}
          icon={ICONS[tag]}
          expirationDate={expiredAt}
          price={
            storePlans
              .map(({ discountedPrice, price }) => discountedPrice || price)
              .sort((a, b) => (a.value > b.value ? 1 : -1))[0]
          }
          disabled={!canBuy}
        />
      ))}
    </PageWrapper>
  );
};

export default Store;
