import { Fetcher } from '@bit/together-price.core.api.fetch';
import { fetchBilling } from '@bit/together-price.core.api.services';
import { MoneyLike } from '@bit/together-price.core.monetary';
import useSWR from 'swr';

export type Period =
  | 'MONTHLY'
  | 'TWO_MONTH'
  | 'THREE_MONTH'
  | 'SIX_MONTH'
  | 'ANNUAL'
  | 'FOREVER';

export type StorePlan = {
  id: number;
  name: string;
  period: Period;
  discountedPrice?: MoneyLike;
  price: MoneyLike;
};

export type StoreItem = {
  canBuy: boolean;
  createdAt: number;
  expiredAt?: number;
  id: number;
  tag: string;
  storePlans: StorePlan[];
};

export type Catalog = {
  id: number;
  name: string;
  storeItems: StoreItem[];
};

export const useStoreCatalog = (catalogName: string) =>
  useSWR(
    catalogName ? `/store/getStoreCatalog?name=${catalogName}` : null,
    fetchBilling as Fetcher<Catalog>,
    {
      revalidateOnMount: true,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      refreshWhenOffline: false,
      refreshWhenHidden: false,
      refreshInterval: 0,
      dedupingInterval: 8000,
    }
  );

export type OrderActivity = {
  name: string;
  price: MoneyLike;
  createdAt: number;
  expirationDate: number;
};
export const useOrderActivities = () =>
  useSWR(
    '/store/getOrderActivities',
    fetchBilling as Fetcher<OrderActivity[]>,
    {
      revalidateOnMount: true,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      refreshWhenOffline: false,
      refreshWhenHidden: false,
      refreshInterval: 0,
      suspense: true,
      dedupingInterval: 8000,
    }
  );

export type OrderStatus = {
  status: 'IN_PROCESS' | 'SUCCESS' | 'FAILED' | 'INVALID';
  expiredAt: string;
  price: MoneyLike;
  name: string;
  errorMessage?: string;
  errorsCode?: string[];
};
export type OrderStatusOptions = {
  uuid?: string;
  isReady?: boolean;
};
export const useOrderStatus = ({ uuid, isReady }: OrderStatusOptions) =>
  useSWR(
    `/store/checkOrderStatus?uuid=${uuid}`,
    fetchBilling as Fetcher<OrderStatus>,
    {
      refreshInterval: isReady ? 0 : 2000,
      suspense: true,
      dedupingInterval: 8000,
    }
  );

export type BuyStoreItemFieldType = {
  tag: string;
  orderPlan: Omit<StorePlan, 'id'>;
};
export type BuyStoreItemOptions = {
  catalogId: number;
  name: string;
  uuid: string;
  items: BuyStoreItemFieldType[];
  paymentSource: 'CARD' | 'WALLET';
};
export const buyStoreItem = ({
  name,
  catalogId,
  uuid,
  items,
  paymentSource,
}: BuyStoreItemOptions): Promise<string> =>
  fetchBilling('/store/buy', {
    method: 'POST',
    body: JSON.stringify({
      name,
      catalogId,
      uuid,
      paymentSource,
      items,
    }),
  });
