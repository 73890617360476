import React from 'react';
import PageWrapper from '@bit/together-price.core.components.page-wrapper';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import { Trans } from 'react-i18next';
import { useNavigateToUrl } from '@bit/together-price.core.components.app-context';
import OrderActivities from '@/components/OrderActivities';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },
}));

const StorePurchases = () => {
  const navigateToUrl = useNavigateToUrl();
  const history = useHistory();
  const classes = useStyles();

  return (
    <PageWrapper
      activeTab='home'
      navigateToUrl={navigateToUrl}
      classes={{ mobileInner: classes.root }}
      FullTopAppBarProps={{
        mobileProps: {
          title: <Trans i18nKey='Purchases.title' />,
          titleLine: true,
          onBack: history.goBack,
        },
      }}
    >
      <OrderActivities />
    </PageWrapper>
  );
};

export default StorePurchases;
