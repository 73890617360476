import {
  fade,
  Grid,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Typography,
} from '@material-ui/core';
import React, { FC, ReactNode, ReactElement } from 'react';
import { Trans } from 'react-i18next';
import { Money, MoneyLike } from '@dev-togetherprice/monetary';
import clsx from 'clsx';
import moment from 'moment';
import { updateFunnel } from '@dev-togetherprice/api.user';
import { useNavigateToUrl } from '@dev-togetherprice/components.app-context';
import getColumnName from '@/utils/getColumnName';

const useStyles = makeStyles((theme) => ({
  root: {
    border: `1px solid ${theme.palette.primary.light}`,
    borderRadius: theme.spacing(2.5),
    overflow: 'hidden',
    cursor: 'pointer',
    marginBottom: theme.spacing(4),
    '-webkit-tap-highlight-color': 'transparent',
  },
  disabled: {
    opacity: 0.6,
  },
  informationLayer: {
    borderBottom: `1px solid ${theme.palette.primary.light}`,
    backgroundColor: fade(theme.palette.primary.light, 0.2),
    width: '100%',
  },
  information: {
    alignItems: 'flex-start',
  },
  icon: { marginTop: '.9rem', '& svg': { width: 40, height: 40 } },
  priceLayer: {
    height: 70,
  },
  price: {
    marginLeft: theme.spacing(2),
  },
  from: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  title: {},
  description: {
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
    },
  },
}));
export type StoreItemProps = {
  title: ReactNode;
  description: ReactNode;
  icon: ReactElement;
  price?: MoneyLike;
  disabled?: boolean;
  expirationDate?: number;
  link: string;
};
const StoreItem: FC<StoreItemProps> = ({
  title,
  description,
  icon,
  price,
  disabled,
  expirationDate,
  link,
}) => {
  const classes = useStyles();
  const navigateToUrl = useNavigateToUrl();
  return (
    <Grid
      className={clsx(classes.root, { [classes.disabled]: disabled })}
      onClick={() => {
        if (disabled) return;
        const funnel = `click_${getColumnName(link)}`;
        updateFunnel({ column: funnel });
        navigateToUrl(`/store/${link}`);
      }}
      container
      direction='column'
    >
      <Grid className={classes.informationLayer}>
        <ListItem className={classes.information}>
          <ListItemIcon className={classes.icon}>{icon}</ListItemIcon>
          <ListItemText>
            <Typography
              className={classes.title}
              variant='subtitle1'
              color='textPrimary'
            >
              {title}
            </Typography>
            <Typography
              className={classes.description}
              variant='body2'
              color='textSecondary'
            >
              {description}
            </Typography>
          </ListItemText>
        </ListItem>
      </Grid>
      {!disabled && (
        <Grid
          className={classes.priceLayer}
          container
          direction='row'
          alignItems='center'
          justifyContent='center'
        >
          <Typography
            variant='subtitle2'
            color='primary'
            className={classes.from}
          >
            <Trans
              i18nKey='from'
              components={{
                child: (
                  <Typography
                    className={classes.price}
                    variant='h1'
                    component='span'
                    color='primary'
                  />
                ),
              }}
              values={{ value: Money.from(price).toString() }}
            />
          </Typography>
        </Grid>
      )}
      {disabled && (
        <Grid
          className={classes.priceLayer}
          container
          direction='column'
          alignItems='center'
          justifyContent='center'
        >
          <Typography variant='subtitle2' color='primary'>
            <Trans i18nKey='activeService' />
          </Typography>
          <Typography variant='body1' color='textSecondary'>
            <Trans
              i18nKey='Payment.expiryDate'
              values={{ date: moment(expirationDate).format('DD/MM/yyyy') }}
            />
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default StoreItem;
