import moment, { DurationInputArg2 } from 'moment';
import { getI18n } from 'react-i18next';
import { Period } from '@/api/store';

export const getDuration = (period: Period): [number, DurationInputArg2] => {
  switch (period) {
    case 'MONTHLY':
      return [1, 'month'];
    case 'TWO_MONTH':
      return [2, 'month'];
    case 'THREE_MONTH':
      return [3, 'month'];
    case 'SIX_MONTH':
      return [6, 'month'];
    case 'ANNUAL':
      return [1, 'year'];
    default:
      return null;
  }
};

export const sortByPeriod = (period1: Period, period2: Period): number => {
  const duration1 = getDuration(period1);
  const duration2 = getDuration(period2);
  if (!duration1) return 1;
  if (!duration2) return -1;
  const time1 = moment().add(duration1[0], duration1[1]).valueOf();
  const time2 = moment().add(duration2[0], duration2[1]).valueOf();
  return time1 - time2;
};

export const translateDuration = (period: Period): string => {
  const duration = getDuration(period);
  if (!duration) return getI18n().t('Periods.forever');
  return getI18n().t(
    `Periods.${duration[0] > 1 ? `${duration[1]}s` : duration[1]}`,
    { duration: duration[0] }
  );
};

const convertPeriod2Date = (period: Period): string => {
  const duration = getDuration(period);
  if (duration) {
    return moment().add(duration[0], duration[1]).format('MM/yyyy');
  }
  return null;
};

export default convertPeriod2Date;
