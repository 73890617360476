/* eslint-disable import/extensions */
import { SupportedLocales, getStripeTranslations } from '@dev-togetherprice/hooks.use-stripe-translation';
import { useEffect, useState } from 'react';
import { getI18n } from 'react-i18next';
import useRegisteredTranslation from './useRegisteredTranslation';

// TODO: quick fix for i18n issue, to be removed

const useStripeTranslation = () => {
    const [stripeTranslations, setStripeTranslations] = useState<any>({ en: {} });
    useEffect(() => {
        const language = getI18n().language as SupportedLocales;
        const fetchStripeTranslations = async () => {
            const trans = await getStripeTranslations(language);
            setStripeTranslations({ [language]: trans });
        };
        fetchStripeTranslations();
    }, []);

    const { t } = useRegisteredTranslation('useStripeTranslation', stripeTranslations);

    return { tStripe: t, stripeTranslations };
};

export default useStripeTranslation;
