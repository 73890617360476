import {
  ListItem,
  ListItemIcon,
  Typography,
  makeStyles,
} from '@material-ui/core';
import React, { FC, useState } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import PageWrapper from '@bit/together-price.core.components.page-wrapper';
import { useNavigateToUrl } from '@bit/together-price.core.components.app-context';
import { Trans, useTranslation } from 'react-i18next';
import Button from '@bit/together-price.core.components.button';
import { updateFunnel } from '@bit/together-price.core.api.user';
import PlanSelect from '@/components/PlanSelect';
import { useStoreCatalog } from '@/api/store';
import ICONS from '@/assets/icons';
import useCatalogName from '@/hooks/useCatalogName';
import getColumnName from '@/utils/getColumnName';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },
  icon: {
    '& svg': {
      width: 40,
      height: 40,
    },
  },
  titleWrapper: {
    marginBottom: theme.spacing(3),
  },
  text: {},
  title: {},
  description: {
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
    },
  },
  noRenewal: { fontSize: 10 },
  button: {
    marginTop: 'auto',
  },
}));
type MatchProps = {
  service: string;
};
export type StoreServiceProps = {
  match: RouteComponentProps<MatchProps>['match'];
};
const StoreService: FC<StoreServiceProps> = ({ match }) => {
  const navigateToUrl = useNavigateToUrl();
  const { service } = match.params;
  const classes = useStyles();
  const history = useHistory();
  const [value, setValue] = useState('');
  const { t } = useTranslation();
  const icon = ICONS[service];
  const catalogName = useCatalogName();
  const { data: catalog } = useStoreCatalog(catalogName);
  if (!catalogName) {
    history.goBack();
    return <div />;
  }

  if (!catalog) return <div />;

  const currentItem = catalog.storeItems.find(({ tag }) => tag === service);

  if (!currentItem || !currentItem.canBuy) {
    history.goBack();
    return <div />;
  }
  return (
    <PageWrapper
      activeTab='home'
      classes={{ mobileInner: classes.root }}
      FullTopAppBarProps={{
        mobileProps: {
          title: <Trans i18nKey='store' />,
          titleLine: true,
          onBack: () => history.goBack(),
        },
      }}
      navigateToUrl={navigateToUrl}
    >
      <ListItem className={classes.titleWrapper} disableGutters>
        <ListItemIcon className={classes.icon}>{icon}</ListItemIcon>
        <Typography className={classes.title} variant='h1' color='textPrimary'>
          <Trans
            i18nKey={`Catalog.${service}.title`}
            components={{ child: <div /> }}
          />
        </Typography>
      </ListItem>
      {(
        t(`Service.${service}.description`, {
          returnObjects: true,
        }) as Array<string>
      ).map((description) => (
        <Typography
          className={classes.description}
          key={description.replaceAll(' ', '_')}
          variant='body2'
          color='textSecondary'
        >
          {description}
        </Typography>
      ))}
      <PlanSelect onChange={setValue} plans={currentItem.storePlans || []} />
      <Typography
        className={classes.noRenewal}
        variant='body2'
        color='textSecondary'
      >
        <Trans i18nKey='Service.subDescription' />
      </Typography>
      <Button
        className={classes.button}
        variant='contained'
        color='primary'
        disabled={!value}
        onClick={async () => {
          const funnel = `buy_now_${getColumnName(service)}`;
          await updateFunnel({ column: funnel });
          navigateToUrl(`/store/${service}/payment/${value}`);
        }}
      >
        <Trans i18nKey='Service.buy' />
      </Button>
    </PageWrapper>
  );
};

export default StoreService;
