import React, { useState } from 'react';
import PageWrapper from '@bit/together-price.core.components.page-wrapper';
import { useHistory } from 'react-router-dom';
import { Typography, makeStyles, Grid, fade } from '@material-ui/core';
import { Trans, useTranslation } from 'react-i18next';
import { Money } from '@bit/together-price.core.monetary';
import TCard from '@bit/together-price.core.components.tcard';
import {
  useApp,
  useNavigateToUrl,
} from '@bit/together-price.core.components.app-context';
import Button from '@bit/together-price.core.components.button';
import { nanoid } from 'nanoid';
import { updateFunnel } from '@bit/together-price.core.api.user';
import { buyStoreItem, useStoreCatalog } from '@/api/store';
import convertPeriod2Date, {
  translateDuration,
} from '@/utils/convertPeriod2Date';
import useCatalogName from '@/hooks/useCatalogName';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    padding: theme.spacing(4),
  },
  header: {
    zIndex: 100,
  },
  process: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    paddingTop: theme.spacing(5),
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  description: {
    fontWeight: 400,
    marginBottom: theme.spacing(4),
  },
  purchaseText: { marginTop: theme.spacing(6) },
  planCard: {
    backgroundColor: fade(theme.palette.primary.light, 0.2),
    borderColor: theme.palette.primary.light,
    borderStyle: 'solid',
    borderWidth: 3,
    borderRadius: theme.spacing(2.5),
    padding: theme.spacing(2.5, 4),
    margin: theme.spacing(2, 0),
  },
  planCardTitle: {
    marginBottom: theme.spacing(1.5),
  },
  planCardTitleLeft: {
    flex: 1,
  },
  button: {
    marginTop: 'auto',
  },
}));

const StorePayment = ({ match }) => {
  const uuid = nanoid();
  const navigateToUrl = useNavigateToUrl();
  const { service, plan } = match.params;
  const [paymentType, setPaymentType] = useState('');
  const [walletBalance, setWalletBalance] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const { setError } = useApp();
  const { t } = useTranslation();
  const history = useHistory();
  const classes = useStyles();
  const catalogName = useCatalogName();
  const { data: catalog } = useStoreCatalog(catalogName);
  if (!catalogName) {
    history.goBack();
    return <div />;
  }

  if (!catalog) return <div />;

  const currentItem = catalog.storeItems.find(({ tag }) => tag === service);

  if (!currentItem || !currentItem.canBuy) {
    history.goBack();
    return <div />;
  }

  const currentPlan = currentItem.storePlans.find(
    ({ period }) => period === plan
  );

  const onConfirm = async () => {
    if (
      paymentType === 'WALLET' &&
      walletBalance <
        (currentPlan.discountedPrice?.value || currentPlan.price?.value)
    ) {
      setError(<Trans i18nKey='Payment.INSUFFICIENT_BALANCE' />);
      return false;
    }
    const { id, ...orderPlan } = currentPlan;
    setIsLoading(true);
    await updateFunnel({ column: 'confirm_payment' });
    buyStoreItem({
      name: 'test_catalog',
      catalogId: catalog.id,
      uuid,
      items: [{ tag: service, orderPlan }],
      paymentSource: paymentType as 'CARD' | 'WALLET',
    })
      .then(async (res) => {
        await updateFunnel({ column: 'payment_successful' });
        navigateToUrl(`/store/${res}/status`);
      })
      .catch((err) => {
        console.error(err);
        const message = err.data.message || JSON.stringify(err);
        setError(message);
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <PageWrapper
      activeTab='home'
      navigateToUrl={navigateToUrl}
      classes={{ mobileInner: classes.root, header: classes.header }}
      FullTopAppBarProps={{
        mobileProps: {
          title: <Trans i18nKey='store' />,
          titleLine: true,
          onBack: history.goBack,
        },
      }}
    >
      <Typography className={classes.title} variant='h1' color='textPrimary'>
        <Trans i18nKey='Payment.title' />
      </Typography>
      <Typography
        className={classes.description}
        variant='caption'
        color='textSecondary'
      >
        <Trans i18nKey='Payment.description' />
      </Typography>
      <TCard
        showWallet
        onError={(err) => setError(err)}
        onSelect={({ type, balance }) => {
          if (type) {
            setPaymentType(type.toUpperCase());
            setWalletBalance(balance);
          } else {
            setPaymentType('');
          }
        }}
      />
      <Typography
        className={classes.purchaseText}
        variant='subtitle1'
        color='textPrimary'
      >
        <Trans i18nKey='Payment.purchase' />
      </Typography>
      <Grid className={classes.planCard} container direction='column'>
        <Grid
          className={classes.planCardTitle}
          container
          direction='row'
          justifyContent='space-between'
        >
          <Typography className={classes.planCardTitleLeft} color='primary'>
            <Trans
              i18nKey={`Catalog.${service}.title`}
              components={{
                child: (
                  <Trans
                    i18nKey='for'
                    components={{
                      child: (
                        <Trans
                          i18nKey={translateDuration(currentPlan.period)}
                        />
                      ),
                    }}
                  />
                ),
              }}
            />
          </Typography>
          <Typography variant='h2' color='primary'>
            {Money.toString(currentPlan.discountedPrice || currentPlan.price)}
          </Typography>
        </Grid>
        <Typography
          className={classes.description}
          variant='caption'
          color='textSecondary'
        >
          {(
            t(`Service.${service}.description`, {
              returnObjects: true,
            }) as string[]
          ).map((description) => description)}
        </Typography>
        <Typography variant='caption' color='textSecondary' align='right'>
          <Trans
            i18nKey='Payment.expiryDate'
            values={{ date: convertPeriod2Date(currentPlan.period) || '-' }}
          />
        </Typography>
      </Grid>
      <Button
        className={classes.button}
        onClick={onConfirm}
        variant='contained'
        color='primary'
        disabled={!paymentType || isLoading}
        fullWidth
      >
        <Trans i18nKey='Payment.confirm' />
      </Button>
    </PageWrapper>
  );
};

export default StorePayment;
