import { Money } from '@bit/together-price.core.monetary';
import { fade, Grid, makeStyles, Typography } from '@material-ui/core';
import clsx from 'clsx';
import React, { FC, useState } from 'react';
import { Trans } from 'react-i18next';
import { StorePlan } from '@/api/store';
import AcceptIcon from '@/icons/AcceptIcon';
import { sortByPeriod, translateDuration } from '@/utils/convertPeriod2Date';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2, 0),
  },
  itemWrapper: {
    overflow: 'hidden',
    width: '100%',
    margin: theme.spacing(1, 0),
    borderRadius: theme.spacing(2.5),
  },
  item: {
    backgroundColor: fade(theme.palette.primary.light, 0.2),
    padding: theme.spacing(5, 4),
    borderRadius: theme.spacing(2.5),
    borderWidth: 3,
    borderColor: 'transparent',
    cursor: 'pointer',
    borderStyle: 'solid',
    '-webkit-tap-highlight-color': 'transparent',
    position: 'relative',
  },
  discountedItem: {
    padding: theme.spacing(2, 4),
  },
  selected: {
    borderColor: theme.palette.primary.dark,
  },
  iconWrapper: {
    position: 'absolute',
    top: -20,
    right: -20,
    width: 40,
    height: 40,
    transform: 'rotate(45deg)',
    backgroundColor: theme.palette.primary.dark,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
  },
  priceWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-end',
  },
  discountedPrice: {
    textDecoration: 'line-through',
  },
  icon: {
    position: 'absolute',
    top: 0,
    right: 0,
    fontSize: 15,
  },
}));
export type PlanSelectProps = {
  plans: Array<StorePlan>;
  onChange: (key: string) => void;
};
const PlanSelect: FC<PlanSelectProps> = ({ plans, onChange }) => {
  const classes = useStyles();
  const [value, setValue] = useState('');
  return (
    <Grid className={classes.root} container direction='column'>
      {plans
        .sort((a, b) => sortByPeriod(a.period, b.period))
        .map(({ period, price, discountedPrice }) => {
          const hasDiscountedPrice =
            discountedPrice && discountedPrice.value !== price.value;
          return (
            <Grid className={classes.itemWrapper}>
              <Grid
                onClick={() => {
                  setValue(period);
                  onChange(period);
                }}
                className={clsx(classes.item, {
                  [classes.selected]: value === period,
                  [classes.discountedItem]: hasDiscountedPrice,
                })}
                key={period}
                item
              >
                <Grid
                  container
                  direction='row'
                  alignItems='center'
                  justifyContent='space-between'
                  key={`${period}-wrapper`}
                >
                  <Typography
                    key={`${period}-duration-wrapper`}
                    variant='subtitle2'
                    color='primary'
                  >
                    <Trans
                      key={`${period}-duration-for`}
                      i18nKey='for'
                      components={{
                        child: (
                          <Trans
                            key={`${period}-duration-text`}
                            i18nKey={translateDuration(period)}
                          />
                        ),
                      }}
                    />
                  </Typography>
                  <Grid
                    key={`${period}-price-wrapper`}
                    className={classes.priceWrapper}
                  >
                    {hasDiscountedPrice && (
                      <Typography
                        className={classes.discountedPrice}
                        key={`${period}-discounted-price`}
                        variant='caption'
                        color='textSecondary'
                      >
                        {Money.from(price).toString()}
                      </Typography>
                    )}
                    <Typography
                      key={`${period}-price`}
                      variant='h1'
                      color='primary'
                    >
                      {Money.from(
                        hasDiscountedPrice ? discountedPrice : price
                      ).toString()}
                    </Typography>
                  </Grid>
                </Grid>
                {value === period && (
                  <>
                    <div
                      key={`${period}-iconWrapper`}
                      className={classes.iconWrapper}
                    />
                    <AcceptIcon
                      key={`${period}-icon`}
                      className={classes.icon}
                    />
                  </>
                )}
              </Grid>
            </Grid>
          );
        })}
    </Grid>
  );
};

export default PlanSelect;
