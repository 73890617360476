import { Grid, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { Trans } from 'react-i18next';
import { useOrderActivities } from '@/api/store';
import PurchaseItem from '../PurchaseItem';

const useStyles = makeStyles(() => ({
  noPurchase: {
    flex: 1,
  },
}));
const OrderActivities = () => {
  const classes = useStyles();
  const { data: orderActivities } = useOrderActivities();
  if (orderActivities?.length === 0) {
    return (
      <Grid
        className={classes.noPurchase}
        container
        justifyContent='center'
        alignContent='center'
        direction='column'
      >
        <Typography variant='caption' color='textSecondary'>
          <Trans i18nKey='Purchases.noPurchase' />
        </Typography>
      </Grid>
    );
  }
  return (
    <Grid container direction='column'>
      {orderActivities?.map(({ name, price, expirationDate, createdAt }) => (
        <PurchaseItem
          key={`${name}-order`}
          title={
            <Trans
              i18nKey={`Catalog.${name}.title`}
              components={{ child: <div /> }}
            />
          }
          price={price}
          expirationDate={expirationDate}
          purchaseDate={createdAt}
        />
      ))}
    </Grid>
  );
};

export default OrderActivities;
