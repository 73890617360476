import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

const AcceptIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox='0 0 14 11' style={{ fill: 'none' }}>
    <path d='M2 5L5.5 8.5L12 2' stroke='white' strokeWidth='3' />
  </SvgIcon>
);

export default AcceptIcon;
