import React from 'react';
import CustomerServiceIcon from '@/icons/CustomerServiceIcon';
import FreeCommissionIcon from '@/icons/FreeCommissionIcon';
import MoneyTransferIcon from '@/icons/MoneyTransferIcon';

const ICONS = {
  AUTO_PAYOUT_FEATURE: <MoneyTransferIcon />,
  NO_FEE: <FreeCommissionIcon />,
  DEDICATED_SUPPORT: <CustomerServiceIcon />,
};

export default ICONS;
