import i18next from 'i18next';
import intervalPlural from 'i18next-intervalplural-postprocessor';
import { initReactI18next } from 'react-i18next';
import en from './en/translation.json';
import es from './es/translation.json';
import it from './it/translation.json';
import pt from './pt/translation.json';

const instance = i18next
  .createInstance()
  .use(initReactI18next)
  .use(intervalPlural);

instance.init({
  resources: {
    en: {
      translation: en,
    },
    es: {
      translation: es,
    },
    it: {
      translation: it,
    },
    pt: {
      translation: pt,
    },
  },
  lng: 'es',
  fallbackLng: 'es',
  interpolation: {
    escapeValue: false,
  },
  react: {
    bindI18n: 'languageChanged loaded',
    bindI18nStore: 'added',
  },
});

export default instance;
