import { Money, MoneyLike } from '@bit/together-price.core.monetary';
import { fade, Grid, makeStyles, Typography } from '@material-ui/core';
import moment from 'moment';
import React, { FC, ReactNode } from 'react';
import { Trans } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: fade(theme.palette.primary.light, 0.35),
    borderRadius: theme.spacing(2),
    padding: theme.spacing(3),
    margin: theme.spacing(2, 0),
  },
  caption: { fontWeight: 400 },
}));

export type PurchaseItemProps = {
  title: ReactNode;
  price: MoneyLike;
  expirationDate: number;
  purchaseDate: number;
};
const PurchaseItem: FC<PurchaseItemProps> = ({
  title,
  price,
  expirationDate,
  purchaseDate,
}) => {
  const classes = useStyles();
  return (
    <Grid className={classes.root} container direction='column'>
      <Grid
        container
        direction='row'
        alignItems='center'
        justifyContent='space-between'
      >
        <Typography variant='subtitle1' color='textPrimary'>
          {title}
        </Typography>
        <Typography variant='h2' color='primary'>
          {Money.from(price).toString()}
        </Typography>
      </Grid>
      <Grid container direction='row' justifyContent='space-between'>
        <Typography
          className={classes.caption}
          variant='caption'
          color='textSecondary'
        >
          <Trans
            i18nKey='Payment.expiryDate'
            values={{ date: moment(expirationDate).format('MM/yyyy') }}
          />
        </Typography>
        <Typography
          className={classes.caption}
          variant='caption'
          color='textSecondary'
        >
          <Trans
            i18nKey='Purchases.onThe'
            values={{ date: moment(purchaseDate).format('DD/MM/yyyy') }}
          />
        </Typography>
      </Grid>
    </Grid>
  );
};

export default PurchaseItem;
