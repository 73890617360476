import React from 'react';
import { Switch, Route, BrowserRouter as Router } from 'react-router-dom';
import AbTest from '@dev-togetherprice/components.ab-test/dist/AbTest';
import { QueryParamProvider } from 'use-query-params';
import { Experiment } from '@dev-togetherprice/components.ab-test';
import { useApp } from '@dev-togetherprice/components.app-context';
import Store from './Store';
import StoreService from './StoreService';
import StorePayment from './StorePayment';
import StorePaymentStatus from './StorePaymentStatus';
import StorePurchases from './StorePurchases';

export type RoutesProps = {};
const Routes: React.FC<RoutesProps> = () => {
  const { basename } = useApp();
  return (
    <AbTest>
      <Router basename={basename}>
        <Experiment name='TP_STORE' defaultVariant='A' swrConfig={{ revalidateOnFocus: true }}>
          <QueryParamProvider ReactRouterRoute={Route}>
            <Switch>
              <Route path='/store/purchases' component={StorePurchases} />
              <Route
                path='/store/:uuid/status'
                component={StorePaymentStatus}
              />
              <Route
                path='/store/:service/payment/:plan'
                component={StorePayment}
              />
              <Route path='/store/:service' component={StoreService} />
              <Route path='/store' component={Store} />
            </Switch>
          </QueryParamProvider>
        </Experiment>
      </Router>
    </AbTest>
  );
};

export default Routes;
